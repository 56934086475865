$c-background: #00ff00;
$c-text: black;

$trans: 0.3s ease;
$border: 0.08em solid black;

$f-size: 3.275rem;
$f-size--mobile: 1.5rem;
$f-size--small: 1.375rem;
$f-size--small--mobile: 15px;

@function em-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}em;
}

$breakpoints: (
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1440,
  xxl: 1800
);

@mixin bp($size) {
  @if $size == sm {
    @content;
  } @else {
    $bp: map-get($breakpoints, $size);
    @media screen and (min-width: #{em-calc($bp)}) {
      @content;
    }
  }
}

@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/3469DF_0_0.eot');
  src: url('../assets/fonts/3469DF_0_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3469DF_0_0.woff2') format('woff2'),
    url('../assets/fonts/3469DF_0_0.woff') format('woff'),
    url('../assets/fonts/3469DF_0_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Formular';
  src: url('../assets/fonts/3469DF_1_0.eot');
  src: url('../assets/fonts/3469DF_1_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3469DF_1_0.woff2') format('woff2'),
    url('../assets/fonts/3469DF_1_0.woff') format('woff'),
    url('../assets/fonts/3469DF_1_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

::selection {
  background: black;
  color: $c-background;
}

h1,
h2,
h3,
p,
li {
  margin: 0;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;

  @include bp(md) {
    font-size: 80%;
  }

  @include bp(lg) {
    font-size: 100%;
  }
}

body {
  font-family: 'Formular';
  background: $c-background;
  color: $c-text;
  margin: 0;
  padding: 0;
  padding-top: 2rem;
  width: 100%;

  @include bp(md) {
    padding-top: 6rem;
  }
}

p {
  font-size: $f-size--mobile;
  margin-bottom: 2rem;
  @include bp(md) {
    margin-bottom: 5rem;
    font-size: $f-size;
  }
}

a {
  color: currentColor;

  &:visited,
  &:focus {
    color: currentColor;
  }
}

button {
  appearance: none;
  background: none;
  border-radius: none;
  font-size: 2.5rem;
  border: none;
  padding: 20px;
  transition: 50ms ease;
  font-family: 'Formular';

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.9);
  }
  // font-family: 'Formular';
  // border: 4px solid black;

  // @include bp(md) {
  //   font-size: $f-size;
  // }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: pink;
}

.small {
  font-size: $f-size--small--mobile;
  line-height: 1.5;

  @include bp(md) {
    font-size: $f-size--small;
  }
}

.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Header {
  @extend .container;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 2rem;

  @include bp(md) {
    margin-bottom: 5rem;
  }
}

.Cards {
  @extend .container;
  display: flex;
  flex-flow: row wrap;
  hyphens: auto;

  &--is-party {
    .Cards__tree {
      animation: dance 0.5s 2s linear infinite alternate both;
    }
  }

  &__item {
    position: relative;
    width: calc(100% / 2);
    padding: 0.3rem;

    @include bp(md) {
      width: calc(100% / 3);
    }
    @include bp(xl) {
      width: calc(100% / 4);
    }

    &:hover {
      cursor: url('/images/hover-cursor.png') 36 72, auto;
    }

    &--is-mine {
      .Cards {
        &__tree {
          path,
          line,
          polyline {
            animation: grow 1.5s linear both;
            transform-origin: center bottom;
          }
          path[stroke-width] {
            fill: none;
            stroke: white;
          }
          line,
          polyline {
            stroke: white;
          }
          path {
            fill: white;
          }
        }
      }
    }

    &:hover {
      .Cards {
        &__tree {
          path[stroke-width] {
            fill: none;
            stroke: white;
          }
          line,
          polyline {
            stroke: white;
          }
          path {
            fill: white;
          }
        }
        &__name {
          opacity: 1;
        }
      }
    }
  }

  &__tree {
    path,
    line,
    polyline {
      transition: $trans;
    }
  }

  &__name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: $f-size;
    opacity: 0;
    transition: $trans;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.Illustration {
  margin-bottom: 3rem;
}

.Form {
  @extend .container;
  &__block {
    margin-bottom: 2rem;

    @include bp(lg) {
      margin-bottom: 5rem;
      // display: flex;
      // align-items: baseline;
    }
  }
  &__input {
    width: 100%;
    display: inline-block;
    appearance: none;
    border: none;
    background: none;
    font-family: 'Formular';
    border-bottom: $border;
    margin-bottom: 2rem;
    font-size: $f-size--small;
    transition: $trans;

    &::placeholder {
      color: rgba(black, 0.4);
    }

    @include bp(md) {
      font-size: $f-size;
      margin-right: 2rem;
      width: calc(100% / 3 - 2rem);
      margin-bottom: 1rem;

      &:hover,
      &:focus {
        border-color: white;
        outline: none;
      }
    }
  }

  &__submit {
    width: 100%;

    @include bp(md) {
      width: calc(100% / 3);
    }
  }
}

.button {
  appearance: none;
  background: none;
  border: $border;
  padding: 1rem 0 0.8rem;
  transition: $trans;
  font-size: $f-size--small;

  @include bp(md) {
    font-size: $f-size;
  }

  &:hover {
    background: black;
    color: white;
  }

  &--is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.Loader {
  position: fixed;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000;

  &__content {
    text-align: center;
  }

  &__tree {
    width: 200px;
    height: 200px;
    animation: rotate 3s linear infinite both;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes dance {
  0% {
    transform: skewX(0);
  }
  40% {
    transform: skewX(0);
  }
  60% {
    transform: skewX(10deg);
  }
  80% {
    transform: skewX(10deg);
  }
  100% {
    transform: skewX(0);
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
